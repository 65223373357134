@import './colors.scss';


body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

body.fontLoaded {
  font-family: 'Roboto Condensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#root {
  color: $white;
  background-color: $background;
}


h1,
h2,
h4 {
  font-weight: lighter;
}

h3 {
  font-family: 'Eczar', Times, 'Times New Roman', serif;
  font-weight: lighter;
}

.sub {
  font-family: 'Roboto Condensed', Times, 'Times New Roman', serif;
  font-size: 16px;
}

.fixu {
  animation: spin infinite 3s linear;
  height: 15vmin;
  pointer-events: none;
  margin-top: 46px;
}

.logo {
  height: 10vmin;
  width: 10vmin;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5vmin;
}

.drawer {
  background-color: $background !important;
  border-right: 0 !important;
}

.drawerLi {
  font-size: 0.9rem !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}