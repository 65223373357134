@import "~react-vis/dist/style";

:root {
  --default-color: #63C9FF;
  --alert-color: #C95360;
  --primary-color: white;
  --secondary-color: #5a5a5a;
  --success-color: #70c770;
}

* {
  box-sizing: border-box;
font-family: 'Oxygen', sans-serif;
}

a {
  cursor: pointer;
}
button {
  cursor: pointer;
}
h1, h2, h3, h4, h5, h6 {
  color: white;
  font-weight: 400 !important;
font-family: 'Nunito', sans-serif !important;
text-align: center;

}
p {
font-family: 'Nunito', sans-serif;
  text-align: center;
  color: white;
  font-weight: 100;
}
html {
    height: 100%;
  font-family: 'Oxygen', sans-serif !important;
  width: 100%;
    min-height: 100vh;
}

body {
  min-height: 100vh;
  margin: 0;
  width: 100%;

}
#root {
  min-height: 100vh;
  width: 100%;

background-color: #076585;  /* fallback for old browsers */
background: -webkit-linear-gradient(to top, rgb(83, 203, 240), #076585);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to top, rgb(83, 203, 240), #076585); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}
.main-header {
  display: flex;
  justify-content: center;
  height: 4rem;
  width: 100%;
  position: relative;
  

z-index: 99999;
}
.main-header > nav {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 813px) {
  nav > .header-list {
    display: none;
  }
}
.account-header {
  position: fixed;
  bottom: 0;
  display: flex;
  height: 3.5rem;
  width: 100%;
  background-color: rgb(88, 160, 181);
  order: 1;  
  z-index: 99999999;

}
.account-header > nav {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.icon-button-wrapper {
  cursor: pointer;
  position: relative;
}
.icon-button-wrapper button {
  cursor: pointer;
  position: relative;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  text-align: center;
  border: transparent;
  transition: filter 0.2s ease;
  -webkit-transition: filter 0.2s ease;
  -moz-transition: filter 0.2s ease;
  -ms-transition: filter 0.2s ease;
  -o-transition: filter 0.2s ease;
}
.icon-button-wrapper button:focus {
  outline: none;
}
.icon-button-wrapper {
    overflow: hidden;
}
.icon-button-wrapper button i {
  color: white;
  font-size: 1.5rem;
  text-align: center;
}
.icon-button-wrapper button:hover {
  filter:  drop-shadow(0px 15px 3px rgba(24,24,24,0.3));
  -webkit-filter:  drop-shadow(0px 15px 3px rgba(24,24,24,0.3));
          
}
.icon-button-wrapper button:hover i {
color: rgb(124, 251, 173);
}

.ripple-effect::before {
  content: '';
  background-color: rgba(255, 255, 255, 0.226);
  width: 0.5em;
  height: 0.5rem;
  padding:0;
  margin-top: 1.5rem;
  margin-right: -5rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
  animation: testRipple 0.3s linear backwards;
  -webkit-animation: testRipple 0.3s linear backwards;
}
.ripple-effect {

}

.achievement-open {
    -webkit-font-smoothing: antialiased;
    color: #fff;
    box-sizing: border-box;
    font-family: 'Oxygen', sans-serif;
    display: flex;
    margin: 0;
    padding: 0;
    background-color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: achievementOpen 0.5s ease forwards, achievementWidth 0.5s ease forwards 0.5s;
    -webkit-animation: achievementOpen 0.5s ease forwards, achievementWidth 0.5s ease forwards 0.5s;
}

@keyframes achievementOpen {
  from {
    position: absolute;
}
  to {
    position: fixed;

    top: 40%;
    right: 40%;

  
}
}
@keyframes achievementWidth {
  from {
    border-radius: 50%;
    width: 0%;
    height: 0%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
  to {
    top: 0;
    right: 0;
      width: 100%;
      height: 100%;
  }
}
@keyframes testRipple {
  0% {
      opacity: 1;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
}
90% {
  opacity: 0.4
}
  100% {
    transform: scale(20);
    -webkit-transform: scale(20);
    -moz-transform: scale(20);
    -ms-transform: scale(20);
    -o-transform: scale(20);
    opacity: 0.1;
}
}
.icon-button-wrapper {
  position: relative;
  transition: order 0.3s ease;
  -webkit-transition: order 0.3s ease;
  -moz-transition: order 0.3s ease;
  -ms-transition: order 0.3s ease;
  -o-transition: order 0.3s ease;
  width: 100%;
  height: 100%;
}
.icon-button-wrapper:hover p {
  opacity: 1;
  transform: translateX(-10px);
  -webkit-transform: translateX(-10px);
  -moz-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  -o-transform: translateX(-10px);
}
.icon-button-wrapper p {
  transition: opacity 0.2s ease, transform 0.2s ease;
  text-align: left;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 1.3rem;
  right: -8rem;
  color: white;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease, transform 0.2s ease;
  -moz-transition: opacity 0.2s ease, transform 0.2s ease;
  -ms-transition: opacity 0.2s ease, transform 0.2s ease;
  -o-transition: opacity 0.2s ease, transform 0.2s ease;
}
footer {
  height: 12vh;
  width: 100%;
  background-color: rgb(94, 94, 94);
  display: none;

}
main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 90vh;
  overflow: hidden;
  padding-bottom: 2rem;

}

@media only screen and (min-width: 813px) {
  main {
    flex-direction: row;
    min-height: 80vh;
  }
  .account-header {
    order: 0 ;
    height: 100vh;
    width: 6%;
    background-color: transparent;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    position: static;
}
.account-header:hover {
  background-color: rgba(36, 34, 34, 0.575);
  box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.185);
}
  .account-header nav {
    flex-direction: column;
    height: 100%;
  }
  .information-block-text > h1 {
    text-align: left;
}
.information-block-text > p {
  text-align: left;
}
  .information-block-text-reverse > h1 {
    text-align: right;
}
.information-block-text-reverse > p {
  text-align: right;
}
.icon-button-wrapper {
  height: 4rem;
}
.icon-button-wrapper button {
  height: 100%;
}


  footer {
    display: flex;
  }
  .menu-list {
    overflow: hidden;
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  flex-direction: column;

}
.menu-item {
  overflow: hidden;
  transition: all 0.2s ease;
  cursor: pointer;
  height: 10%;
  font-size: 0.6rem !important;
  height: 10% !important;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.menu-item-icon {
  transition: all 0.2s ease;
  font-size: 1.5rem;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.menu-item:hover {
  filter:  drop-shadow(0px 15px 3px rgba(24,24,24,0.3));
  -webkit-filter:  drop-shadow(0px 15px 3px rgba(24,24,24,0.3));
          
}
.menu-item-icon:hover i {
  transition: all 0.2s ease;
  color: #4DCCBD;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
} 
.menu-item-active {
  transition: all 0.2s ease;
  color: #EFEBCE;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  border-bottom: 3px solid white;
  border-radius: 0.2rem;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  -ms-border-radius: 0.2rem;
  -o-border-radius: 0.2rem;
}   
.menu-item-active > i {
      transition: all 0.2s ease;
  color: rgb(124, 251, 173);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
@media only screen and (min-width: 1025px) {
  .menu-item {
    border: none;
    width: 100% !important;
    margin: 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  .menu-item-active .menu-item-icon {
    transform: translateY(-0.3rem);
    -webkit-transform: translateY(-0.3rem);
    -moz-transform: translateY(-0.3rem);
    -ms-transform: translateY(-0.3rem);
    -o-transform: translateY(-0.3rem);
}
}
.new-menu-item {
  text-align: center;
  display: flex;
  justify-content: center;
  fill: rgba(255, 255, 255, 0);
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  stroke: white;
  stroke-width: 25px;
}
.new-menu-item:hover {
 fill: rgb(255, 255, 255);
 stroke-width: 0;

}
.new-menu-item-active {
  transition: all 0.2s ease-out;
  fill: rgb(255, 255, 255);
  stroke-width: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}
.new-menu-item:hover .menu-item-container > p {
  font-weight: bold;
}
.new-menu-item:active {
  font-weight: 800;
fill: rgb(255, 255, 255);
}
.menu-item-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 5rem;
  width: 100%;
  
}

.menu-item-wrapper > div {
    text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
    align-items: center;
  

}

.menu-item-wrapper > div > svg {
  margin: auto;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
    align-items: center;
}
path {
  overflow: hidden;
}
.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  height: 100vh;
}

.App-header {
  display: flex;
  font-size: 14px;
  color: white;
}

.container {
  text-align: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.landing-page-container {
  display: flex;
  flex-direction: row;
}
.card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
  width: 100%;
  flex-grow: 1;

  border-radius: 5px;
  background-size: cover;

    transition: all 0.5s ease-in forwards;
}
.card-title {
  width: 100%;
  height: 15%;
  border-bottom: 1px solid rgb(204, 204, 204);
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-body {
  -webkit-perspective: 100px; /* Safari 4-8 */
  perspective: 100px;
  width: 100%;
  min-height: 80%;
  flex-grow: 1;
  height: 80%;


}
.card-footer {
  width: 100%;
  max-height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(204, 204, 204);
}
.card-footer p {
  cursor: pointer;
}
.card-footer p:hover {
  transition: all 0.2s ease-out ;
  -webkit-transition: all 0.2s ease-out ;
  -moz-transition: all 0.2s ease-out ;
  -ms-transition: all 0.2s ease-out ;
  -o-transition: all 0.2s ease-out ;
      color: rgb(124, 251, 173);

}
.card-container {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
}
.landing-page-block {
  display: flex;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
width: 90%;
height: 50px;
border-radius: 50px;
background-color: #6edc6c;
background-size: cover;
box-shadow: 0px 1px 10px rgba(0,0,0,0.2);
font-size: 20px;
color: #ffffff;
text-decoration: none solid rgb(255, 255, 255);
letter-spacing: 2px;
border: none !important;
outline: none !important;
}
.button-inside {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 100;
}
.button:active {
  outline: none;
  border: none;
}
.button:hover {
  box-shadow: 0 10px 10px rgba(0,0,0,0.2);
  transition: background-color ease 0.2s, box-shadow ease 0.2s;
  background-color: #4e974d;
  -webkit-transition: background-color ease 0.2s, box-shadow ease 0.2s;
  -moz-transition: background-color ease 0.2s, box-shadow ease 0.2s;
  -ms-transition: background-color ease 0.2s, box-shadow ease 0.2s;
  -o-transition: background-color ease 0.2s, box-shadow ease 0.2s;
}
.button:not(:hover) {
    transition: background-color ease 0.2s;
}
.button:focus {outline:0;}
.information-block-wrapper {
  border-left: 3px solid white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.information-block-image {
  width: 30%;
}
.information-block-image > i {
  font-size: 5rem;
}
.information-block-image > img {
  width: 100%;
  filter: drop-shadow(0px 15px 15px  rgba(0, 0, 0, 0.2));
  -webkit-filter: drop-shadow(0px 15px 15px  rgba(0, 0, 0, 0.2));
}
.information-block-text {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.information-block-text > h1 {
  margin: 0;

  filter: drop-shadow(0px 15px 5px  rgba(0, 0, 0, 0.2));
  -webkit-filter: drop-shadow(0px 15px 5px  rgba(0, 0, 0, 0.2));
}
.information-block-text > p {
  color: #ffffffc8;
  letter-spacing: 3px;
}
.information-block-wrapper-reverse {
  border-right: 3px solid white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.information-block-image-reverse {
  width: 30%;
}
.information-block-image-reverse > i {
  font-size: 5rem;
}
.information-block-image-reverse > img {
  width: 100%;
  filter: drop-shadow(0px 15px 15px  rgba(0, 0, 0, 0.2));
  -webkit-filter: drop-shadow(0px 15px 15px  rgba(0, 0, 0, 0.2));
}
.information-block-text-reverse {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.information-block-text-reverse > h1 {
  margin: 0;
  color: white;
  filter: drop-shadow(0px 15px 5px  rgba(0, 0, 0, 0.2));
  -webkit-filter: drop-shadow(0px 15px 5px  rgba(0, 0, 0, 0.2));
}
.information-block-text-reverse > p {
  color: #ffffffc8;
  letter-spacing: 3px;
}
.header-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
}
.header-item {
cursor: pointer;
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
    color: rgb(235, 255, 252);
    text-transform: uppercase;
}
.header-item:hover {
  transition: height 0.2s ease-out ;
  -webkit-transition: height 0.2s ease-out ;
  -moz-transition: height 0.2s ease-out ;
  -ms-transition: height 0.2s ease-out ;
  -o-transition: height 0.2s ease-out ;
  color: rgb(124, 251, 173);
}
.header-item:after {
  display:block;
  content: '';
  border-bottom: solid 3px #3d83bd;
  transform: scaleX(0);
  transition: transform 150ms ease-in-out;
  -webkit-transition: transform 150ms ease-in-out;
  -moz-transition: transform 150ms ease-in-out;
  -ms-transition: transform 150ms ease-in-out;
  -o-transition: transform 150ms ease-in-out;
}
.header-item:hover:after { transform: scaleX(1); }
.header-item.fromRight:after{ transform-origin:100% 50%; }
.header-item.fromLeft:after{  transform-origin:  0% 50%; }

.input {
    border: none;
    padding: 0.5rem;
    width: 15rem;
    color: rgba(255, 255, 255, 0.767);
    border: 1px solid #7575757c;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    text-align: center;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    background-color: transparent;


}
input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.432) !important;
}

input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.432) !important;
}

input::-ms-placeholder {
  color: rgba(0, 0, 0, 0.432) !important;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.432) !important;
}

.input:focus {
  box-shadow: 0px 1px 5px rgba(68, 68, 68, 0.383);
  outline: none;
}

.input-rounded {
    border-radius: 1rem;
    border-radius: 1rem;
      -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
.input-basic {
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
}
.input-underline {
  border: none;
  border-bottom: 2px solid rgb(182, 222, 226);
}
.input-error {
  box-shadow: 0 2px 3px -3px rgb(250, 113, 113);
  border-bottom: 2px solid rgb(250, 77, 77);
}
.input-group-wrapper {
  text-align: center;
  height: 4rem;
}
.input-group-wrapper p {
  text-align: center;
  color: rgb(214, 82, 82);
}
.input-label-group, .input-heading-group {
  text-align: center;
  padding:5px;
}
.input-label, input-heading {
  color: #8c8c8c;
  padding: 0.3rem;
  font-size: 0.9rem;
}
.input-heading {
  display:inline;
}

.icon-input-wrapper {
  position: relative;
  width: 15rem;
}
.icon-input-wrapper > input {
  width: 100%;
  height: 100%;
}

.icon-input-wrapper > i {
  position: absolute;
  color: white;
  height: 100%;
  width: 2rem;
  text-align: center;
  padding: 0.5rem;

}


.button-basic {
  cursor: pointer;
  position: relative;
  font-size: 0.8rem;
font-family: 'Oxygen', sans-serif;
  color: white;
  height: 2rem;
  background-color: #70c770;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none !important;
}
.button-basic:focus {
  outline:none;
}
.button-basic:active {
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -webkit-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -moz-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -ms-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -o-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    background-color: rgb(76, 160, 76);
}
.button-basic:not(:active) {
  transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -webkit-transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -moz-transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -ms-transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -o-transition: box-shadow 0.1s ease, background-color 0.1s ease;
}
.button-basic:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.214);
}

.button-rounded {
  border-radius: 1rem !important;
  -webkit-border-radius: 1rem !important;
  -moz-border-radius: 1rem !important;
  -ms-border-radius: 1rem !important;
  -o-border-radius: 1rem !important;
}
.button-alert {
  cursor: pointer;
  position: relative;
  font-size: 0.8rem;
font-family: 'Oxygen', sans-serif;
  color: white;
  height: 2rem;
  background-color: var(--alert-color);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none !important;
}
.button-alert:focus {
  outline:none;
}
.button-alert:active {
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -webkit-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -moz-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -ms-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -o-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
.button-alert:not(:active) {
  transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -webkit-transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -moz-transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -ms-transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -o-transition: box-shadow 0.1s ease, background-color 0.1s ease;
}
.button-outlined {
  cursor: pointer;
  position: relative;
  font-size: 0.8rem;
  font-family: 'Oxygen';
  color: var(--default-color);
  height: 2rem;
  background-color: transparent;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 1px solid rgba(140, 140, 140, 0.212);
}
.button-outlined:focus {
  outline:none;
}
.button-outlined:active {
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -webkit-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -moz-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -ms-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -o-transition: box-shadow 0.2s ease, background-color 0.2s ease;
}
.button-outlined:not(:active) {
  transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -webkit-transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -moz-transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -ms-transition: box-shadow 0.1s ease, background-color 0.1s ease;
  -o-transition: box-shadow 0.1s ease, background-color 0.1s ease;
}
.button-outlined:hover {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.214);
}

.button-plain {
  cursor: pointer;
  position: relative;
  font-size: 0.8rem;
  font-family: 'Oxygen';
  color: var(--default-color);
  height: 2rem;
  background-color: transparent;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
}
.button-plain:focus {
  outline:none;
}
.button-plain:active {
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -webkit-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -moz-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -ms-transition: box-shadow 0.2s ease, background-color 0.2s ease;
  -o-transition: box-shadow 0.2s ease, background-color 0.2s ease;
}
.button-plain:not(:active) {
  transition: background-color 0.1s ease;
  -webkit-transition: background-color 0.1s ease;
  -moz-transition: background-color 0.1s ease;
  -ms-transition: background-color 0.1s ease;
  -o-transition: background-color 0.1s ease;
}
.button-plain:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.214);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}

.ripple::before {
	content: '';
	position: absolute;
	width: inherit;
	height: inherit;
  left: -3%;
	top:-18%;
	border-radius: inherit;
	opacity: 0;
	pointer-events: none;
	z-index: -1;
	animation: riple 0.5s forwards;
	-webkit-animation: riple 0.5s forwards;
	-webkit-border-radius: inherit;
	-moz-border-radius: inherit;
	-ms-border-radius: inherit;
  -o-border-radius: inherit;
  animation-iteration-count: 2;
}
.ripple {

}

.popup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 90%;
  border-radius: 0.3rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.239);
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  animation: swingIn 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  -webkit-animation: swingIn 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}
.popup > p {

}
.popup-success {
    background-color: rgba(147, 233, 147, 0.746);
}
.popup-error {
    background-color: rgba(224, 24, 24, 0.746);
}
.popup-icon {
  position: absolute;
  margin-left: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  border-left: 1px solid white;
  height: 100%;
}
.popup-text {
  width: 75%;
  text-align: center;
  
}
.popup-text > p {
    color: white;
}
.fieldset {
  border: none;
  border-top: 1px solid rgba(238, 238, 238, 0.651);
}
.fieldset > legend {
  padding: 0 1rem 0 1rem;
  color: black;
  text-align: center;
}

.profile-container, .family-container, .eco-container, .group-container {
  width: 100%;
  overflow-x: hidden;
  background-color: white;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.329);
  position: relative;
  padding-bottom: 6rem;

}
.profile-info, .family-info, .eco-info, .group-info {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin-top: 1rem;
}
.profile-avatar {
  display: flex;
  width: 10%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;

}
.profile-details {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}
.profile-avatar > img {
  width: 5rem;
  height: 5rem;
    border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 0.5rem;
  border: 0.2rem solid rgb(82, 175, 252);

}
.divider {
  border: 0.5px solid;
  border-color: rgba(116, 191, 241, 0.534);
  width: 100%; 
}
.divider-secondary {
  border-color: rgba(181, 181, 181, 0.116);
}
.profile-achievements {
  width: 95%;
}
.achievement {
  border: 1px solid #8080800a;
    border-radius: 0.3rem;
    box-shadow: 0px 1px 1px #00000038;
    width: 11rem;
    height: 15rem;
  position: relative;
  display: flex;
  flex-direction: column;  
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .achievement {
    width: 100%;
  }
  
}
.achievement-image {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;

}
.achievement-image:hover {
    transition: all 0.2s ease-in;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.274));
    -webkit-filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.274));
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
}
.achievement-image > i {
  color: rgba(35, 204, 223, 0.577);
  font-size: 3rem;
}
.achievement-locked {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background-color: rgba(128, 128, 128, 0.733);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.achievement-locked > i {
  font-size: 2rem;
  color: rgb(227, 148, 148);
}
.achievement-level {
  position: absolute;
  right: 0;
  margin: 0.5rem;
}
.achievement-level > i {
  font-size: 2rem;
  color: grey;
}
.--level-BRONZE > i {
  color: rgb(162, 128, 40);
}
.--level-SILVER > i {
  color: rgb(191, 191, 191);
}
.--level-GOLD > i {
  color: gold;
}
.profile-combined-achievement {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.profile-combined-achievement > i {
  color: rgb(123, 208, 155);
  font-size: 10rem;
}
.profile-combined-achievement-level {
  min-height: 24px;
}
.profile-combined-achievement-level > i {
  font-size: 1.5rem;
}
.profile-tip {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.199);
  height: 7rem;
  border-radius: 0.2rem;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  -ms-border-radius: 0.2rem;
  -o-border-radius: 0.2rem;
}
.radio-input {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  border: 2px solid rgb(39, 140, 180);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: transparent;
}
.radio-input-inner {
  position: relative;
  width: 0.5rem;
  height: 0.5rem;
  background-color: rgb(39, 140, 180);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
.radio-input-field {
  display: flex;
  align-items: center;

}
.new-electricity-consumption {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.edit-electricity-consumption {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.electricity-consumption-type {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;

}
.switch-wrapper {
  padding: 0.2rem;
  width: 3.5rem;
  border-radius: 1rem;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.212);
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.switch-wrapper > button {
  background-color: #d0d0d0;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.219);
  transition:all 0.2s ease;
  -webkit-transition:all 0.2s ease;
  -moz-transition:all 0.2s ease;
  -ms-transition:all 0.2s ease;
  -o-transition:all 0.2s ease;
  margin-left: 0;
}
.switch-on > button {
    transform: translateX(1.8rem);
    background-color: #278cb4;
    -webkit-transform: translateX(1.8rem);
    -moz-transform: translateX(1.8rem);
    -ms-transform: translateX(1.8rem);
    -o-transform: translateX(1.8rem);
} 
.household-info-wrapper {
  width: 100%;
}
.menu-list {
  display: flex;
  justify-content: space-around;
  list-style: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .menu-list {
    justify-content: space-around;
  }
}
@media screen and (min-width: 320px) and (max-width:812px) and (orientation: landscape) { 
  .menu-list {
    justify-content: space-around;
  }
  .achievement-progress-inner {
  width: 40% !important;
  height: 100%;
}
}
.menu-list::-webkit-scrollbar {
    display: none;
}
.menu-item {  
  width: 5rem;
  margin: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  height: 100%;
}
.menu-item-icon {
  transition: all 0.2s ease;
  position: relative;
  font-size: 1.5rem;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-filter: s;
}
.menu-item-alert {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  right: -13px;
  top: 15px;
  height: 1rem;
  width: 1.5rem;
  background-color: rgba(255, 0, 0, 1);
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  font-size: 1rem;
}
@media only screen and (max-width: 813px) {
  .menu-item-alert {
    top: 15px;
    right: -15px;
  }
}
.mobile-navbar-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  animation: 0.2s ease mobileNav forwards;
  -webkit-animation: 0.2s ease-out mobileNav forwards;
}
.mobile-navbar-wrapper-close {
  animation: 0.1s linear mobileNavLeave forwards;
  -webkit-animation: 0.1s linear mobileNavLeave forwards;
}
.mobile-navbar-inner {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.817);
}
.mobile-navbar-inner .header-list {
  flex-direction: column;
  justify-content: center;
}
.mobile-navbar-inner .header-list .header-item {
  text-align: center;
  height: 5rem;
}
@media only screen and (min-width: 813px) {
.mobile-navbar-button {
  display: none;
}
}
.mobile-navbar-button >i {
  font-size: 2rem;
}
.mobile-navbar-button {

  width: 100%;
  display: none;
  justify-content: flex-end;
  align-items: baseline;
  margin: 1rem;

}
.achievement-progress {
  visibility: hidden;
  opacity: 0;
  border-radius: inherit;
  -webkit-border-radius: inherit;
  -moz-border-radius: inherit;
  -ms-border-radius: inherit;
  -o-border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(52, 52, 52, 0.719);
  position: absolute;
  width: 100%;
  height: 100%;
}
.achievement-progress-level {
  font-size: 1.5rem;
}
.progress-show {
  animation: progressShow 0.2s ease forwards;
  -webkit-animation: progressShow 0.2s ease forwards;
}
.progress-hide {
  animation: progressHide 0.2s ease forwards;
  -webkit-animation: progressHide 0.2s ease forwards;
}
@keyframes progressShow {
  0% {
  visibility: visible;
  opacity: 0;

  }
  100% {
      visibility: visible;
  opacity: 1;
  }
}
@keyframes progressHide {
  0% {
  visibility: visible;
  opacity: 1;

  }
  90% {
  opacity: 0;
  }
  100% {
          visibility: hidden;
  }
}
.achievement-progress-inner {
  width: 70%;
  height: 100%;
}
@media only screen and (max-width: 812px) {
  .mobile-navbar-button {
    display: flex;
  }
}
@keyframes mobileNav {
  0% {
    transform: translateX(300px);
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -ms-transform: translateX(300px);
    -o-transform: translateX(300px);
}
  100% {


  }
}
@keyframes mobileNavLeave {
  0% {

}
  100% {
    transform: translateX(300px);
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -ms-transform: translateX(300px);
    -o-transform: translateX(300px);

  }
}
@keyframes swingIn {
  0% {
    opacity: 0;
    transform: rotateX(-20deg);
    -webkit-transform: rotateX(-20deg);
    -moz-transform: rotateX(-20deg);
    -ms-transform: rotateX(-20deg);
    -o-transform: rotateX(-20deg);
}
  100% {
    opacity: 1;
}
}
@keyframes riple {
	0% {
    transform: scale3d(0.3, 0.3, 1);
      background: rgba(111, 148, 182, 0.626);
	}
	25%, 50% {
		opacity: 1;
  }
  70%{
    border: 5px solid rgba(111,148,182,0.1);
    background-color: transparent;
  }
	100% {
        border: 1px solid rgba(111,148,182,0.1);
		opacity: 0;
		transform: scale3d(1.1, 1.1, 1);
		-webkit-transform: scale3d(1.1, 1.1, 1);
		-moz-transform: scale3d(1.1, 1.1, 1);
		-ms-transform: scale3d(1.1, 1.1, 1);
		-o-transform: scale3d(1.1, 1.1, 1);
}
}
@keyframes Ripple {
  0% {
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.4);
  }
  100% {
        box-shadow: 15px 15px 15px rgba(255, 255, 255, 0.2);
  }
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  [class^="u-grid"] {
  min-width: 100%;
  flex-basis: 100%;
}

    [class="container-grid"] {
    width: 100% !important;
        padding: 0 !important;
    flex-basis: 100% !important;
  }
  #call-to-action {

  }
  #landing-page-image {
    display: none;
  }
  .information-block-wrapper {
    flex-direction: column;
  }
  .information-block-image {
    order: 2;
  }
    .information-block-wrapper-reverse {
    flex-direction: column;
  }
  .information-block-image-reverse {
    order: 2;
  }
  .account-header {
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.219);
        outline: 1px solid #969696c7;
  }
}

.loading-spinner {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-top: 2px solid white;
  border-right: 2px solid rgb(67, 67, 214);
  border-bottom: 2px solid rgb(67, 67, 214);
  border-left: 2px solid rgb(67, 67, 214);
  animation: spinner 0.5s linear infinite;
}

@keyframes spinner {
  0% {

  }
  100% {
    transform: rotate(360deg)
  }
}
@media only screen and (min-width: 813px) {
  .information-block-image {
    display: flex;
    width: 20%;
    justify-content: center;
    align-items: center;
  }
  .information-block-text {
    width: 80%;
  }
    .information-block-image-reverse {
    display: flex;
    width: 20%;
    justify-content: center;
    align-items: center;
  }
  .information-block-text-reverse {
    width: 80%;
  }
  .profile-container, .family-container, .eco-container, .group-container {
    width: 93%;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  }
}

.family-header {
    width: 100%;
    height: 4rem;

}
.member-wrapper {
  width: 100%;
}



@keyframes fadeIn {

  from {
      opacity: 0;
      transform: translateY(-500px);
      -webkit-transform: translateY(-500px);
      -moz-transform: translateY(-500px);
      -ms-transform: translateY(-500px);
      -o-transform: translateY(-500px);
}
  to {
      opacity: 1;
    }
  }

@keyframes fadeInRight {

  from {
      opacity: 0;
      transform: translateX(-500px);
      -webkit-transform: translateX(-500px);
      -moz-transform: translateX(-500px);
      -ms-transform: translateX(-500px);
      -o-transform: translateX(-500px);
}
  to {
      opacity: 1;
    }
  }


@keyframes rotateIn {
  from {
    opacity: 0;
    width: 0;
    height: 0px !important;
    transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -o-transform: rotate(30deg);
}

}
.transition {
  all: inherit;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  height: 100%;
  transition: all 0.5s ease-in forwards;

}
.fade-in {
  animation: fade-in 0.3s ease-out forwards;
  -webkit-animation: fade-in 0.3s ease-out forwards;
}
.fade-in-right {
  animation: fadeInRight 0.3s ease-out forwards;
  -webkit-animation: fadeInRight 0.3s ease-out forwards;
  -webkit-transition: all 0.5s ease-in forwards;
  -moz-transition: all 0.5s ease-in forwards;
  -ms-transition: all 0.5s ease-in forwards;
  -o-transition: all 0.5s ease-in forwards;
}
.slow-fade-in-top {
    
    animation: slowFadeIn 0.5s ease-out forwards;
    -webkit-animation: slowFadeIn 0.5s ease-out forwards;
    -webkit-transition: all 0.5s ease-in forwards;
    -moz-transition: all 0.5s ease-in forwards;
    -ms-transition: all 0.5s ease-in forwards;
    -o-transition: all 0.5s ease-in forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slowFadeIn {

  0% {
    filter: blur(5px);
    opacity: 0;
    transform: translateY(-100px);
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    -webkit-filter: blur(5px);
}
  100% {

    opacity: 1;
  }
}
.row {
  min-width: 100%;
  flex-wrap: nowrap;
  flex-basis: 100%;
  display: flex;
}

[class*="grid-"] {
  display: inline-block;
  justify-content: inherit;
  align-items: center;
}

.text-secondary {
  color: #5a5a5a;
}
.text-alert {
  color: var(--alert-color);
}
.text-default {
  color: var(--default-color)
}
.text-success {
  color: var(--success-color)
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.u-grid-1 {max-width: 8.33%; flex-basis: 8.33%; padding: 0.5rem;  }
.u-grid-2 {max-width: 16.66%; flex-basis: 16.66%; padding: 0.5rem;  }
.u-grid-3 {max-width: 24.99%; flex-basis: 24.99%; padding: 0.5rem;  }
.u-grid-4 {max-width: 33.32%; flex-basis: 33.32%; padding: 0.5rem;  }
.u-grid-5 {max-width: 41.65%; flex-basis: 41.65%; padding: 0.5rem;  }
.u-grid-6 {max-width: 50%;  flex-basis: 50%; padding: 0.5rem;  }
.u-grid-7 {max-width: 58.31%; flex-basis: 58.31%; padding: 0.5rem;}
.u-grid-8 {max-width: 66.64%; flex-basis: 66.64%; padding: 0.5rem;  }
.u-grid-9 {max-width: 74.97%; flex-basis: 74.97%; padding: 0.5rem; }
.u-grid-10 {max-width: 83.3%; flex-basis: 83.3%; padding: 0.5rem;  }
.u-grid-11 {max-width: 91.63%; flex-basis: 91.63%; padding: 0.5rem;  }
.u-grid-12 {min-width: 100%; flex-basis: 100%; max-width: 100%; padding: 0.5rem;  }

@media screen and (max-width: 767px) {
.s-grid-1 {min-width: 8.33% !important; max-width: 8.33% !important; flex-basis: 8.33% !important; padding: 0.5rem;  }
.s-grid-2 {min-width: 16.66% !important; max-width: 16.66% !important; flex-basis: 16.66% !important; padding: 0.5rem;  }
.s-grid-3 {min-width: 24.99% !important; max-width: 24.99% !important; flex-basis: 24.99% !important; padding: 0.5rem;  }
.s-grid-4 {min-width: 33.32% !important; max-width: 33.32% !important; flex-basis: 33.32% !important; padding: 0.5rem;  }
.s-grid-5 {min-width: 41.65% !important; max-width: 41.65% !important; flex-basis: 41.65% !important; padding: 0.5rem;  }
.s-grid-6 {min-width: 50% !important; max-width: 50% !important; flex-basis: 50% !important; padding: 0.5rem;  }
.s-grid-7 {min-width: 58.31% !important; max-width: 58.31% !important; flex-basis: 58.31% !important; padding: 0.5rem;}
.s-grid-8 {min-width: 66.64% !important; max-width: 66.64% !important; flex-basis: 66.64% !important; padding: 0.5rem;  }
.s-grid-9 {min-width: 74.97% !important; max-width: 74.97% !important; flex-basis: 74.97% !important; padding: 0.5rem; }
.s-grid-10 {min-width: 83.3% !important; max-width: 83.3% !important; flex-basis: 83.3% !important; padding: 0.5rem;  }
.s-grid-11 {min-width: 91.63% !important; max-width: 91.63% !important; flex-basis: 91.63% !important; padding: 0.5rem;  }
.s-grid-12 {min-width: 100% !important; flex-basis: 100% !important; max-width: 100%; padding: 0.5rem;  }

}
@media screen and (min-width: 320px) and (max-width:812px) and (orientation: landscape) {
.s-grid-1 {min-width: 8.33% !important; max-width: 8.33% !important; flex-basis: 8.33% !important; padding: 0.5rem;  }
.s-grid-2 {min-width: 16.66% !important; max-width: 16.66% !important; flex-basis: 16.66% !important; padding: 0.5rem;  }
.s-grid-3 {min-width: 24.99% !important; max-width: 24.99% !important; flex-basis: 24.99% !important; padding: 0.5rem;  }
.s-grid-4 {min-width: 33.32% !important; max-width: 33.32% !important; flex-basis: 33.32% !important; padding: 0.5rem;  }
.s-grid-5 {min-width: 41.65% !important; max-width: 41.65% !important; flex-basis: 41.65% !important; padding: 0.5rem;  }
.s-grid-6 {min-width: 50% !important; max-width: 50% !important; flex-basis: 50% !important; padding: 0.5rem;  }
.s-grid-7 {min-width: 58.31% !important; max-width: 58.31% !important; flex-basis: 58.31% !important; padding: 0.5rem;}
.s-grid-8 {min-width: 66.64% !important; max-width: 66.64% !important; flex-basis: 66.64% !important; padding: 0.5rem;  }
.s-grid-9 {min-width: 74.97% !important; max-width: 74.97% !important; flex-basis: 74.97% !important; padding: 0.5rem; }
.s-grid-10 {min-width: 83.3% !important; max-width: 83.3% !important; flex-basis: 83.3% !important; padding: 0.5rem;  }
.s-grid-11 {min-width: 91.63% !important; max-width: 91.63% !important; flex-basis: 91.63% !important; padding: 0.5rem;  }
.s-grid-12 {min-width: 100% !important; flex-basis: 100% !important; max-width: 100%; padding: 0.5rem;  }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
.m-grid-1 {min-width: 8.33% !important; max-width: 8.33% !important; flex-basis: 8.33% !important; padding: 0.5rem;  }
.m-grid-2 {min-width: 16.66% !important; max-width: 16.66% !important; flex-basis: 16.66% !important; padding: 0.5rem;  }
.m-grid-3 {min-width: 24.99% !important; max-width: 24.99% !important; flex-basis: 24.99% !important; padding: 0.5rem;  }
.m-grid-4 {min-width: 33.32% !important; max-width: 33.32% !important; flex-basis: 33.32% !important; padding: 0.5rem;  }
.m-grid-5 {min-width: 41.65% !important; flex-basis: 41.65% !important; padding: 0.5rem;  }
.m-grid-6 {min-width: 50% !important; max-width: 50% !important; flex-basis: 50% !important; padding: 0.5rem;  }
.m-grid-7 {min-width: 58.31% !important; max-width: 58.31% !important; flex-basis: 58.31% !important; padding: 0.5rem;}
.m-grid-8 {min-width: 66.64% !important; max-width: 66.64% !important; flex-basis: 66.64% !important; padding: 0.5rem;  }
.m-grid-9 {min-width: 74.97% !important; max-width: 74.97% !important; flex-basis: 74.97% !important; padding: 0.5rem; }
.m-grid-10 {min-width: 83.3% !important; max-width: 83.3% !important; flex-basis: 83.3% !important; padding: 0.5rem;  }
.m-grid-11 {min-width: 91.63% !important; max-width: 91.63% !important; flex-basis: 91.63% !important; padding: 0.5rem;  }
.m-grid-12 {min-width: 100% !important; flex-basis: 100% !important; max-width: 100%; padding: 0.5rem;  }
}

@media only screen and (min-device-width: 1025px) {
  .l-grid-1 {min-width: 8.33% !important; max-width: 8.33% !important; flex-basis: 8.33% !important; padding: 0.5rem;  }
  .l-grid-2 {min-width: 16.66% !important; max-width: 16.66% !important; flex-basis: 16.66% !important; padding: 0.5rem;  }
  .l-grid-3 {min-width: 24.99% !important; max-width: 24.99% !important; flex-basis: 24.99% !important; padding: 0.5rem;  }
  .l-grid-4 {min-width: 33.32% !important; max-width: 33.32% !important; flex-basis: 33.32% !important; padding: 0.5rem;  }
  .l-grid-5 {min-width: 41.65% !important; max-width: 41.65% !important; flex-basis: 41.65% !important; padding: 0.5rem;  }
  .l-grid-6 {min-width: 50% !important; max-width: 50% !important; flex-basis: 50% !important; padding: 0.5rem;  }
  .l-grid-7 {min-width: 58.31% !important; max-width: 58.31% !important; flex-basis: 58.31% !important; padding: 0.5rem;}
  .l-grid-8 {min-width: 66.64% !important; max-width: 66.64% !important; flex-basis: 66.64% !important; padding: 0.5rem;  }
  .l-grid-9 {min-width: 74.97% !important; max-width: 74.97% !important; flex-basis: 74.97% !important; padding: 0.5rem; }
  .l-grid-10 {min-width: 83.3% !important; max-width: 83.3% !important; flex-basis: 83.3% !important; padding: 0.5rem;  }
  .l-grid-11 {min-width: 91.63% !important; max-width: 91.63% !important; flex-basis: 91.63% !important; padding: 0.5rem;  }
  .l-grid-12 {min-width: 100% !important; flex-basis: 100% !important; max-width: 100%; padding: 0.5rem;  }
  }

  @media only screen and (min-width: 1800px) {
  .xl-grid-1 {min-width: 8.33% !important; max-width: 8.33% !important; flex-basis: 8.33% !important; padding: 0.5rem;  }
  .xl-grid-2 {min-width: 16.66% !important; max-width: 16.66% !important; flex-basis: 16.66% !important; padding: 0.5rem;  }
  .xl-grid-3 {min-width: 24.99% !important; max-width: 24.99% !important; flex-basis: 24.99% !important; padding: 0.5rem;  }
  .xl-grid-4 {min-width: 33.32% !important; max-width: 33.32% !important; flex-basis: 33.32% !important; padding: 0.5rem;  }
  .xl-grid-5 {min-width: 41.65% !important; max-width: 41.65% !important; flex-basis: 41.65% !important; padding: 0.5rem;  }
  .xl-grid-6 {min-width: 50% !important; max-width: 50% !important; flex-basis: 50% !important; padding: 0.5rem;  }
  .xl-grid-7 {min-width: 58.31% !important; max-width: 58.31% !important; flex-basis: 58.31% !important; padding: 0.5rem;}
  .xl-grid-8 {min-width: 66.64% !important; max-width: 66.64% !important; flex-basis: 66.64% !important; padding: 0.5rem;  }
  .xl-grid-9 {min-width: 74.97% !important; max-width: 74.97% !important; flex-basis: 74.97% !important; padding: 0.5rem; }
  .xl-grid-10 {min-width: 83.3% !important; max-width: 83.3% !important; flex-basis: 83.3% !important; padding: 0.5rem;  }
  .xl-grid-11 {min-width: 91.63% !important; max-width: 91.63% !important; flex-basis: 91.63% !important; padding: 0.5rem;  }
  .xl-grid-12 {min-width: 100% !important; flex-basis: 100% !important; max-width: 100%; padding: 0.5rem;  }
  }
.height-1 {height: 8.33%}
.height-2 {height: 16.66%}
.height-3 {height: 24.99%}
.height-4 {height: 33.32%}
.height-5 {height: 41.65%}
.height-6 {height: 50%}
.height-7 {height: 58.31%}
.height-8 {height: 66.64%}
.height-9 {height: 74.97%}
.height-10 {height: 83.3%}
.height-11 {height: 91.63%}
.height-12 {height: 100%}

.gutter-1 .row {
  margin: 1rem;
}
.gutter-2 .row {
  margin: 1.5rem;
}
.gutter-3 .row {
  margin: 2rem;
}
.gutter-4 .row {
  margin: 2.5rem;
}
.gutter-5 .row {
  margin: 3rem;
}
.gutter-6 .row {
  margin: 3.5rem;
}
.gutter-7 .row {
  margin: 4rem;
}
.gutter-8 .row {
  margin: 4.5rem;
}
.gutter-9 .row {
  margin: 5rem;
}
.gutter-10 .row {
  margin: 5.5rem;
}

@media only screen and (max-width: 768px) {
.hidden-m-down {
  display: none;
  visibility: hidden;
}
}


.flex {display: flex}
.row-wrap {flex-wrap: wrap;}

.justify-center {justify-content: center}
.justify-start {justify-content: flex-start}
.justify-end {justify-content: flex-end}
.justify-stretch {justify-content: stretch}
.justify-evenly {justify-content: space-evenly}
.justify-between {justify-content: space-between}
.justify-around {justify-content: space-around}

.align-start {align-items: flex-start}
.align-center {align-items: center}
.align-end {align-items: flex-end}
.align-baseline {align-items: baseline}

.direction-row {flex-direction: row}
.direction-column {flex-direction: column}
.direction-row-reverse {flex-direction: row-reverse}
.direction-column-revers {flex-direction: column-reverse}

.wrap {flex-wrap: wrap}
.no-wrap {flex-wrap: nowrap}

.grow {flex-grow: 1}
.shrink {flex-shrink: 1}

.font-size-1 {font-size: 1rem}
.font-size-2 {font-size: 0.9rem}
.font-size-3 {font-size: 0.8rem}
.font-size-4 {font-size: 0.7rem}
.font-size-5 {font-size: 0.6rem}
.font-size-6 {font-size: 0.5rem}

.weight-100 {font-weight: 100}
.weight-200 {font-weight: 200}
.weight-300 {font-weight: 300}
.weight-400 {font-weight: 400}
.weight-500 {font-weight: 500}
.weight-600 {font-weight: 600}
.weight-700 {font-weight: 700}
.weight-800 {font-weight: 800}

select {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
   text-align: center;
  text-align-last: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  -webkit-animation-name: autofill !important;
  -webkit-animation-fill-mode: both !important;
  animation-name: autofill !important;
  animation-fill-mode: both !important;
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
  transition: "color 9999s ease-out, background-color 9999s ease-out";
  transition-delay: 9999s;
  -webkit-border-radius: none;
  -moz-border-radius: none;
  -ms-border-radius: none;
  -o-border-radius: none;
}

@-webkit-keyframes autofill {
    to {
        background: transparent;
    }
}

.snackbar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 1s ease;
  width: 100%;
  position: absolute;
  z-index: 9999999;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
}

@keyframes snackbarDismiss {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateX(-10px);
    opacity: 0;
  }
}

@keyframes snackbarInTop {
  0% {
    transform: translateY(-100px);
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}
@keyframes snackbarOutTop {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  100% {
    transform: translateY(-200px);
    -webkit-transform: translateY(-200px);
    -moz-transform: translateY(-200px);
    -ms-transform: translateY(-200px);
    -o-transform: translateY(-200px);
    visibility: hidden;
    display: none;
}
}
@keyframes snackbarInSide {
  0% {
    transform: translateX(-500px);
    -webkit-transform: translateX(-500px);
    -moz-transform: translateX(-500px);
    -ms-transform: translateX(-500px);
    -o-transform: translateX(-500px);
}
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
}
}
@keyframes snackbarOutSide {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
}
  100% {
    transform: translateX(-500px);
    -webkit-transform: translateX(-500px);
    -moz-transform: translateX(-500px);
    -ms-transform: translateX(-500px);
    -o-transform: translateX(-500px);
    visibility: hidden;
  }
}
.snackbar-dismiss-out {
  animation: snackbarDismiss 0.3s ease forwards;
  -webkit-animation: snackbarDismiss 0.3s ease forwards;
}
.snackbar-in-from-side {
  animation: snackbarInSide 0.3s ease-out forwards;
  -webkit-animation: snackbarInSide 0.3s ease-out forwards;
}
.snackbar-out-from-side {
  animation: snackbarOutSide 0.3s forwards;
  -webkit-animation: snackbarOutSide 0.3s forwards;
}
.snackbar-full-from-side {
  animation: snackbarInSide 0.3s ease-out forwards,
    snackbarOutSide 0.3s forwards 1.5s;
  -webkit-animation: snackbarInSide 0.3s ease-out forwards,
    snackbarOutSide 0.3s forwards 1.5s;
}
.snackbar-in-from-top {
  animation: snackbarInTop 0.3s ease-out forwards;
  -webkit-animation: snackbarInTop 0.3s ease-out forwards;
}
.snackbar-out-from-top {
  animation: snackbarOutTop 0.3s forwards;
  -webkit-animation: snackbarOutTop 0.3s forwards;
}
.snackbar-full-from-top {
  animation: snackbarInTop 0.3s ease-out forwards,    snackbarOutTop 0.3s forwards 1.5s;
  -webkit-animation: snackbarInTop 0.3s ease-out forwards,    snackbarOutTop 0.3s forwards 1.5s;
}

.snackbar-inner {
    background-color: rgb(51, 51, 51);
    border-radius: 4px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    min-height: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2);
}
.snackbar-inner > p {
  color: whitesmoke;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  font-weight: 100;
  flex-grow: 2;
  text-align: center;
}
.snackbar-success {
  border-left: 5px solid rgb(76, 163, 76);
}
.snackbar-error {
  border-left: 5px solid #c44741;
}
.snackbar-default {
  border-left: 5px solid rgb(39, 39, 39);
}
.snackbar-warning {
  border-left: 5px solid rgb(252, 181, 49);
}
.snackbar-dismiss {
  background-color: transparent;
  border-style: none;
  color: rgb(53, 115, 143);
  flex-grow: 1;
  font-size: 1.5rem
}

.list {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
}


.list span {
  display: block;
}

.listItem {

  border: 1px solid rgb(39, 39, 39);
  display: block;
  background-color: #60a26a;
  list-style-type: none;
  width:100%;
}

.listItem .input-label {
  display:block;
  font-weight: bold;
  color: white;
}

.listItem .bottom {
  width: 100%;
  padding: 10px;
  display: inline-block;
  text-align: center;
  background-color: #c44741;

}
.listItem .top {
  width: 100%;
  padding: 10px;
  display: inline-block;
}

.listItem-action {
  cursor: pointer;
}
.listItem-icon {
  transition: all 0.2s ease;
  font-size: 1.5rem;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.listItem-action:hover {
  filter:  drop-shadow(0px 15px 3px rgba(24,24,24,0.3));
  -webkit-filter:  drop-shadow(0px 15px 3px rgba(24,24,24,0.3));
          
}
.listItem-icon:hover i {
  transition: all 0.2s ease;
  color: rgb(124, 251, 173);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.badge {
  position:relative;
}
.badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -10px;
  right: -30px;
  font-size: .7em;
  background: green;
  color: white;
  height: 18px;
  padding: 2px;
  text-align: center;
  line-height: 18px;
  border-radius: 10%;
  box-shadow: 0 0 1px #333;
}

.rv-radial-chart, .rv-xy-plot {
  margin: 0 auto;
}
.household-badge-wrapper {
  width: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.household-badge-wrapper > i {
  font-size: 4rem;
  color: grey;
}

.user-card-wrapper {
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.user-card-wrapper > i {
  font-size: 4rem;
  color: rgb(234, 250, 11);  
}
.top-users-wrapper {
  width: 90%;
}
@media only screen and (min-width: 768px) {
  .snackbar-inner {
   max-width: 15rem;
  }
}

.modal-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: #00000069;

}
.modal-inner {
  opacity: 0;
  perspective: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
  border: 1px solid rgba(0, 0, 0, 0.089);
  width: 95%;
  transform: translateZ(0);
  z-index: 9999999;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.126);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  animation: 0.1s ease-out modalIn forwards;
  -webkit-animation: 0.1s ease-out modalIn forwards;
}
.snackbar-portal-wrapper {
 position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.snackbar-portal-inner {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  z-index: 999999;
}
@keyframes modalIn {

  0% {
    transform: scale(0.9);
    transform: rotateX(50deg);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
}
  100% {
    opacity: 1;

  }

}
@media only screen and (min-width: 769px) {
  .modal-wrapper {
    width: 100%;
    height: 100%;
  }
  .modal-inner {
    width: 40%;
  }
  .snackbar-portal-inner {
    position: static !important;
  }
  .snackbar-portal-wrapper {
    top: initial !important;
    bottom: initial !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
select, input {
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.statistics {
  background-color: #5a5a5a !important;
}

.statistics-header-icon {
  color: gold;
  font-size: 4em;
  text-align: center;
  display: block;
}

.statistics-medal {
  margin-right: 10px;
  font-size: 1.7em;
}
.gold {
  color: gold;
}
.silver {
  color: silver;
}
.bronze {
  color: darkgoldenrod;
}
.diplom {
  color: lightcyan;
}
.header-icon {
  display: inline-block
}

.ripple-test {
  background-position: center;
  transition: background 0.5s;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -ms-transition: background 0.5s;
  -o-transition: background 0.5s;
  background: transparent radial-gradient(circle, rgba(255, 255, 255, 0.242) 1%, transparent 1%) center/20000%;
  background-size: 100%;
  transition: background 0s forwards;
  -webkit-transition: background 0s forwards;
  -moz-transition: background 0s forwards;
  -ms-transition: background 0s forwards;
  -o-transition: background 0s forwards;
}
.ripple-test:hover {

}
.ripple-test:active {

}